


function Results(props) {
  let p1RoundScore = 0
  let p2RoundScore = 0
  let p1BestRoundScore = 0
  let p2BestRoundScore = 0
  let p1WorstRoundScore = Infinity
  let p2WorstRoundScore = Infinity
  let p1TotalScore = 0
  let p2TotalScore = 0

  for (let i = 0; i < props.roundResults.length; i++) {
    const { p1BadTries, p2BadTries } = props.roundResults[i]
    const p1Score = Math.max(1000 - (p1BadTries * 100), 0)
    const p2Score = Math.max(1000 - (p2BadTries * 100), 0)
    p1RoundScore += p1Score
    p2RoundScore += p2Score
    if (p1Score > p1BestRoundScore) {
      p1BestRoundScore = p1Score
    }
    if (p2Score > p2BestRoundScore) {
      p2BestRoundScore = p1Score
    }
    if (p1Score < p1WorstRoundScore) {
      p1WorstRoundScore = p1Score
    }
    if (p2Score < p2WorstRoundScore) {
      p2WorstRoundScore = p2Score
    }
  }
  for (let i = 0; i < props.totalResults.length; i++) {
    const { p1BadTries, p2BadTries } = props.totalResults[i]
    const p1Score = Math.max(1000 - (p1BadTries * 100), 0)
    const p2Score = Math.max(1000 - (p2BadTries * 100), 0)
    p1TotalScore += p1Score
    p2TotalScore += p2Score
  }

  return (
    <div className="Results">
      <div className="Results-header">
        <div>ROUND {props.missionNumber} RESULTS</div>
      </div>
      <div className="Results-body">
        <div className="Player-board">
          <div className="Player-name">PLAYER 1</div>
          <div className="Board-row">
            <div>ROUND SCORE</div>
            <div>{p1RoundScore}</div>
          </div>
          <div className="Board-row">
            <div>BEST GAME SCORE</div>
            <div>{p1BestRoundScore}</div>
          </div>
          <div className="Board-row">
            <div>WORST GAME SCORE</div>
            <div>{p1WorstRoundScore}</div>
          </div>
          <div className="Board-row Highlight">
            <div>TOTAL SCORE</div>
            <div>{p1TotalScore}</div>
          </div>
        </div>
        <div className="Player-board">
          <div className="Player-name">PLAYER 2</div>
          <div className="Board-row">
            <div>ROUND SCORE</div>
            <div>{p2RoundScore}</div>
          </div>
          <div className="Board-row">
            <div>BEST GAME SCORE</div>
            <div>{p2BestRoundScore}</div>
          </div>
          <div className="Board-row">
            <div>WORST GAME SCORE</div>
            <div>{p2WorstRoundScore}</div>
          </div>
          <div className="Board-row Highlight">
            <div>TOTAL SCORE</div>
            <div>{p2TotalScore}</div>
          </div>
        </div>
      </div>
      <div className="Results-footer">
        <button className="Results-continue" onClick={props.continueGame}>CONTINUE</button>
      </div>
    </div>
  );
}

export default Results;
