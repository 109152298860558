import DividerHorizontal from './DividerHorizontal'

function Menu(props) {
	return (
    <div className="Menu">
      <div className="Menu-title">
        emojibang
      </div>
      <DividerHorizontal num={1}/>
      <div className="Menu-score-list">
        <div className="Menu-score Menu-prev-score">
          <div className="Menu-score-num">
            1
          </div>
          <div className="Menu-score-copy">
            Previous Score
          </div>
        </div>
        <div className="Menu-score Menu-high-score">
          <div className="Menu-score-num">
            1
          </div>
          <div className="Menu-score-copy">
            High Score
          </div>
        </div>
      </div>
      <DividerHorizontal num={1}/>
      <div>
        <button className="Menu-button Menu-start" onClick={props.startGame}>
          start game
        </button>
        <button className="Menu-button Menu-tutorial" onClick={props.startTutorial}>
          tutorial
        </button>
      </div>
    </div>
  )
}

export default Menu