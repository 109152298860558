import './App.css';
import { useState } from 'react'
import Game from './Game'
import Results from './Results'
import Menu from './Menu'
import Tutorial from './Tutorial'

const ANDROID_BLACKLIST = ['🫖', '🫑', '🫓', '🫔', '🫕', '🫒', '🧋', '🫐']
const IS_ANDROID = navigator.userAgent.toLowerCase().indexOf("android") > -1
const CURRENT_DIFFICULTY = parseInt((window.location.hash || "#0").replace(/#/, ''), 10)
const MAX_ROUND = 6

let combos = [
// 1
["👁️‍🗨️",["👁️","🗨️"], 0],
["👨‍🦰",["👨","🦰"], 0],
["👨‍🦱",["👨","🦱"], 0],
["👨‍🦳",["👨","🦳"], 0],
["👨‍🦲",["👨","🦲"], 0],
["👩‍🦰",["👩","🦰"], 0],
["👩‍🦱",["👩","🦱"], 0],
["👩‍🦳",["👩","🦳"], 0],
["👩‍🦲",["👩","🦲"], 0],
["👩‍⚕️",["👩","⚕️"], 0],
["👨‍🎓",["👨","🎓"], 0],
["👩‍🎓",["👩","🎓"], 0],
["👨‍🏫",["👨","🏫"], 0],
["👩‍🏫",["👩","🏫"], 0],
["👨‍🌾",["👨","🌾"], 0],
["👩‍🌾",["👩","🌾"], 0],
["👨‍🍳",["👨","🍳"], 0],
["👩‍🍳",["👩","🍳"], 0],
["👨‍🔧",["👨","🔧"], 0],
["👩‍🔧",["👩","🔧"], 0],
["👨‍🏭",["👨","🏭"], 0],
["👩‍🏭",["👩","🏭"], 0],
["👨‍💼",["👨","💼"], 0],
["👩‍💼",["👩","💼"], 0],
["👨‍🔬",["👨","🔬"], 0],
["👩‍🔬",["👩","🔬"], 0],
["👨‍💻",["👨","💻"], 0],
["👩‍💻",["👩","💻"], 0],
["👨‍🎤",["👨","🎤"], 0],
["👩‍🎤",["👩","🎤"], 0],
["👨‍🎨",["👨","🎨"], 0],
["👩‍🎨",["👩","🎨"], 0],
["👨‍✈️",["👨","✈️"], 0],
["👩‍✈️",["👩","✈️"], 0],
["👨‍🚀",["👨","🚀"], 0],
["👩‍🚀",["👩","🚀"], 0],
["👨‍🚒",["👨","🚒"], 0],
["👩‍🚒",["👩","🚒"], 0],
["👩‍🍼",["👩","🍼"], 0],
["👨‍🍼",["👨","🍼"], 0],
["🧑‍🍼",["🧑","🍼"], 0],
["👨‍👦",["👨","👦"], 0],
["👨‍👧",["👨","👧"], 0],
["👩‍👦",["👩","👦"], 0],
["👩‍👧",["👩","👧"], 0],
["🏳️‍🌈",["🏳️","🌈"], 0],
["🏴‍☠️",["🏴","☠️"], 0],

// ["🐱‍👓",["🐱","👓"], 0],
// ["🐱‍🐉",["🐱","🐉"], 0],
// ["🐱‍💻",["🐱","💻"], 0],
// ["🐱‍🏍",["🐱","🏍"], 0],
// ["🐱‍👤",["🐱","👤"], 0],
// ["🐱‍🚀",["🐱","🚀"], 0],
// ["👩‍👶",["👩","👶"], 0],
// ["👨‍👶",["👨","👶"], 0],

// 2
['🍧',['❄️','🥥'], 1],
['🍦',['❄️','🍭'], 1],
['🎂',['🥞','🍓'], 1],
['🍳',['🔥','🥚'], 0],
['🍪',['🍫','🥜'], 3],
['🍮',['🥚','🍯'], 0],
['🥪',['🍞','🥓'], 0],
['🍔',['🍞','🥩'], 0],
['🐰',['🥕','🥕'], 2],
['🥫',['🔨','🍅'], 0],
['🧃',['🔨','🍎'], 0],
['🍺',['🔨','🍞'], 0],
['🍟',['🔪','🥔'], 0],
['🍻',['🍺','🍺'], 0],
['🍿',['🔥','🌽'], 0],
['🍤',['🔥','🦐'], 0],
['🍱',['🥢','🍣'], 0],
['🍰',['🔪','🎂'], 0],
['🥗',['🔪','🥬'], 0],
['🧊',['❄️','❄️'], 0],
['🧁',['🥤','🎂'], 2],
['🍷',['🔨','🍇'], 0],
['☕️',['🫖','🌰'], 1],
['🍵',['🫖','🌿'], 1],
['🍯',['🐝','🌼'], 0],
['🥠',['🥜','🍯'], 2],
['🍥',['🔨','🐟'], 0],
['🍖',['🦴','🐷'], 0],
['🍗',['🦴','🐔'], 0],
['🥩',['🔪','🐮'], 0],
['🍝',['👩‍🍳','🥫'], 0],
['🍜',['🧑‍🍳','🥡'], 2],
['🍕',['👨‍🍳','🥧'], 2],
['🍹',['🔪','🥭'], 0],
['🧀',['🐮','🥛'], 2],
['🥞',['🍞','🧈'], 2],

// 3
['🌶',['🔥','🥵'], 2],
['🥥',['🌴','🥜'], 2],
['🍈',['🍉','🍉'], 2],
['🫑',['🌶','🌶'], 0],
['🍠',['🥔','🍯'], 0],
['🥨',['🥖','🧂'], 0],
['🍞',['🔪','🥖'], 0],
['🥐',['🔪','🥯'], 2],
['🍩',['🥯','🍫'], 0],
['🌯',['🫓','🥩'], 0],
['🌮',['🔥','🫓'], 1],
['🧇',['🔪','🥞'], 2],
['🫔',['🫓','🌮'], 2],
['🍬',['🔨','🍭'], 2],
['🍨',['🥄','🧊'], 1],
['🫕',['🥣','🧀'], 1],
['🧆',['🍽','🥩'], 2],
['🌭',['🥖','🍖'], 0],
['🥟',['🤏','🫓'], 2],
['🥙',['🫓','🥗'], 2],
['🍛',['🍚','🥕'], 1],
['🥘',['🧈','🍗'], 2],
['🍸',['🫒','🍺'], 0],
['🍾',['✨','🍎'], 2],
['🧋',['☕','🥛'], 0],
['🥂',['🍾','🎉'], 2],
['🍉',['💦','🍈'], 2],
['🍆',['🥚','🌿'], 2],
['🥝',['🔑','🤪'], 2],
['🍌',['🐵','🌴'], 2],
['🧛‍♀',['👩','🧄'], 0],
['🧛',['🧑','🧄'], 0],
['🧛‍♂️',['👨','🧄'], 0],
['🍶',['🍵','🍺'], 2],
['🍼',['👶','🥛'], 0],

// 4
['🍐',['🐻','🅿️'], 2],
['🍏',['🍎','🥬'], 2],
['🍊',['🌳','🏃‍♂️'], 2],
['🍋',['🍊','🌶'], 2],
['🍓',['🫐','🫐'], 2],
['🍒',['👯‍♀️','🍎'], 0],
['🍑',['🅿️','🏖'], 2],
['🥭',['🏃‍♂️','💨'], 2],
['🍍',['🌲','🍎'], 2],
['🍅',['🥫','🦴'], 2],
['🥑',['🌳','🫒'], 2],
['🥦',['🌳','🥬'], 2],
['🥬',['🔪','🌽'], 2],
['🥒',['🍆','🥬'], 2],
['🌽',['🥬','☀️'], 2],
['🥕',['🐰','🌿'], 2],
['🫒',['🥒','💦'], 2],
['🧅',['☝️','🧄'], 3],
['🥔',['🍲','🦶'], 3],
]

let dummyEmojis = ['🐌', '🦐', '🐛', '👅', '🐴', '🦴', '🦞', '🦑', '🚤', '🦃', '🛎️', '🎼', '⚚', '🎩', '👒', '🧢', '🎉', '☃️', '🎀', '🏢', '🏗️', '🏛️', '🏠', '🌇', '🏨', '⛪', '🏫', '🏭', '🕌', '🕋', '🏦', '🧱', '🥨', '🍳', '🥞', '🥓', '🥐', '🥚', '🍩', '🥣', '🥯', '🧇', '🥔', '🥟', '🔬', '🔭', '🧮', '🔪', '🔫', '🔱', '🎭', '🥋', '🎨', '🖼️', '🖌️', '🧶', '🧵', '📐', '✂️', '☕', '🖍️', '🧷', '🏴', '🧉', '🍹', '🥤', '🚰', '🫖', '🧃', '🚱', '🍸', '🍷', '🏺', '🥄', '🍶', '🧋', '☕', '🍺', '🥂', '🍼', '👾', '👹', '👻', '🐙', '☠️', '🤖', '👺', '💩',
'🍮', '🍡', '🍩', '🍭', '🍫', '🦜', '🐈', '🐕', '🐾', '🐹', '🦎', '🐇', '🐦', '🐶', '🐱', '🐠', '🎣', '🐡', '🦈', '🦦', '🥑', '🌭', '🧼', '🛒', '💰', '❤️'
]

dummyEmojis = IS_ANDROID ? dummyEmojis.filter(e => !ANDROID_BLACKLIST.includes(e)) : dummyEmojis

combos = combos.filter(combo => combo[2] <= CURRENT_DIFFICULTY)
combos = IS_ANDROID ? combos.filter(e => !ANDROID_BLACKLIST.includes(e[0])) : combos
combos = IS_ANDROID ? combos.filter(e => !ANDROID_BLACKLIST.includes(e[1][0]) && !ANDROID_BLACKLIST.includes(e[1][1])) : combos

const comboComponents = [...new Set(combos.map(challenge => challenge[1]).flat())]
const comboResults = [...new Set(combos.map(challenge => challenge[0]))]
const distinctDummyEmojis = [...new Set(dummyEmojis.filter(e => !comboComponents.includes(e) && !comboResults.includes(e)))]

function generateFullInventory() {
  const inventory = []
  for (let i = 0; i < 72; i++) {
    let randomDummy
    do {
      randomDummy = distinctDummyEmojis[Math.floor(Math.random() * distinctDummyEmojis.length)];
    }
    while (inventory.includes(randomDummy));
    inventory.push(randomDummy)
  }
  return inventory
}

function generateGame(prevChallenge) {
  let comboChallenge
  do {
    comboChallenge = combos[Math.floor(Math.random() * combos.length)];
  }
  while (comboChallenge[0] === prevChallenge[0]);

  // Pass 1: put random dummy emojies
  const inventory = generateFullInventory()
  const p1Inventory = inventory.slice(0, 36)
  const p2Inventory = inventory.slice(36, 72)

  // Pass 2: Put combo challenge emojies in different inventories
  const p1RandIndex = Math.floor(Math.random() * p1Inventory.length)
  const p2RandIndex = Math.floor(Math.random() * p2Inventory.length)
  if (Math.floor(Math.random() * 2) === 0) {
    p1Inventory[p1RandIndex] = comboChallenge[1][0]
    p2Inventory[p2RandIndex] = comboChallenge[1][1]
  }
  else {
    p1Inventory[p1RandIndex] = comboChallenge[1][1]
    p2Inventory[p2RandIndex] = comboChallenge[1][0]
  }

  // Pass 3: Put random combo emojies to spice things up, 12 per inventory
  for (let i = 0; i < 12; i++) {
    let randomComponent
    do {
      randomComponent = comboComponents[Math.floor(Math.random() * comboComponents.length)];
    }
    while (p1Inventory.includes(randomComponent) || p2Inventory.includes(randomComponent));
    let randIndex
    do {
      randIndex = Math.floor(Math.random() * p1Inventory.length)
    } while (randIndex === p1RandIndex);
    p1Inventory[randIndex] = randomComponent
  }
  for (let i = 0; i < 12; i++) {
    let randomComponent
    do {
      randomComponent = comboComponents[Math.floor(Math.random() * comboComponents.length)];
    }
    while (p1Inventory.includes(randomComponent) || p2Inventory.includes(randomComponent));
    let randIndex
    do {
      randIndex = Math.floor(Math.random() * p2Inventory.length)
    } while (randIndex === p2RandIndex);
    p2Inventory[randIndex] = randomComponent
  }

  return [comboChallenge, p1Inventory, p2Inventory]
}
// function getOrientation(angle) {
//   switch (angle) {
//     case -90: case 90:
//       return 'landscape'
//     default:
//       return 'portrait'
//   }
// }



function App() {
  const [[challenge, p1Inventory, p2Inventory], setGame] = useState(generateGame([]))

  const [gameNonce, setGameNonce] = useState(Date.now())
  const [roundNumber, setRoundNumber] = useState(1)
  const [missionNumber, setMissionNumber] = useState(1)
  const [currentScreen, setCurrentScreen] = useState('menu')
  const [roundResults, setRoundResults] = useState([])
  const [totalResults, setTotalResults] = useState([])
  const [p1BadTries, setP1BadTries] = useState(0)
  const [p2BadTries, setP2BadTries] = useState(0)

  // const [orientation, setOrientation] = useState(getOrientation(window.orientation))
  // const isDesktop = window.screen.width >= 688
  // const orientationChange = useCallback(() => {
  //   setOrientation(getOrientation(window.orientation))
  // }, [])
  // useEffect(() => {
  //   window.addEventListener('orientationchange', orientationChange);
  //   return () => {
  //     window.removeEventListener('orientationchange', orientationChange);
  //   };
  // }, [orientationChange])

  const nextRound = () => {
    setGame(generateGame(challenge))
    setRoundResults([...roundResults, { p1BadTries, p2BadTries }])
    setTotalResults([...totalResults, { p1BadTries, p2BadTries }])
    setP1BadTries(0)
    setP2BadTries(0)
    setGameNonce(Date.now())
    setRoundNumber(roundNumber + 1)
    if (roundNumber >= MAX_ROUND) {
      setCurrentScreen('results')
    }
  }
  const continueGame = () => {
    setGameNonce(Date.now())
    setRoundNumber(1)
    setMissionNumber(missionNumber + 1)
    setRoundResults([])
    setCurrentScreen('game')
  }
  const incrementP1BadTries = () => {
    setP1BadTries(p1BadTries + 1)
  }
  const incrementP2BadTries = () => {
    setP2BadTries(p2BadTries + 1)
  }
  // if (orientation === 'portrait' && !isDesktop) {
  //   return (
  //     <div className="App">
  //       <div className="Wrong-orientation">
  //         <div className="Wrong-orientation-text">Please put your phone in</div>
  //         <div className="Wrong-orientation-text">landscape mode</div>
  //         <div className="Wrong-orientation-picture">🔃📱</div>
  //         <div className="Wrong-orientation-text">&nbsp;</div>
  //         <div className="Wrong-orientation-text">Best experience when</div>
  //         <div className="Wrong-orientation-text">Added to Home Screen</div>
  //         <div className="Wrong-orientation-picture">➕📱</div>
  //       </div>
  //     </div>
  //   )
  // }
  return (
    <div className="App">
      {currentScreen === 'menu' ?
        <Menu
          startGame={() => setCurrentScreen('game')}
          startTutorial={() => setCurrentScreen('tutorial')}
        /> : null}
      {currentScreen === 'tutorial' ?
        <Tutorial
          startGame={() => setCurrentScreen('game')}
          startMenu={() => setCurrentScreen('menu')}
        /> : null}
      {currentScreen === 'results' ?
        <Results
          continueGame={continueGame}
          roundResults={roundResults}
          totalResults={totalResults}
          missionNumber={missionNumber}
        /> : null}
      {currentScreen === 'game' ?
        <Game
          key={gameNonce}
          challenge={challenge}
          p1Inventory={p1Inventory}
          p2Inventory={p2Inventory}
          nextRound={nextRound}
          incrementP1BadTries={incrementP1BadTries}
          incrementP2BadTries={incrementP2BadTries}
          roundNumber={roundNumber}
          maxRound={MAX_ROUND}
        /> : null}
    </div>
  );
}

export default App;
