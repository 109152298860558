
function Tutorial(props) {
	return (
    <div className="Tutorial">
      <div className="Tutorial-title">
        How to play
      </div>
      <div className="Tutorial-instruction">
        1.  Look at the emoji question
      </div>
      <div className="Emoji-formula">
        <div className="Score-landscape">
          <div>Score</div>
          <div>32</div>
        </div>
        <div className="Formula-component">?</div>
        <div className="Formula-sign">+</div>
        <div className="Formula-component">?</div>
        <div className="Formula-sign">=</div>
        <div className={`Formula-result`}>
          🌭
        </div>
      </div>

      <div className="Tutorial-instruction">
        2.  Choose two emojis that could combine to create it
      </div>
      <div className="Emoji-formula">
        <div className="Score-landscape">
          <div>Score</div>
          <div>32</div>
        </div>
        <div className="Formula-component">🔥</div>
        <div className="Formula-sign">+</div>
        <div className="Formula-component">🐶</div>
        <div className="Formula-sign">=</div>
        <div className={`Formula-result`}>
          🌭
        </div>
      </div>

      <div>
        <button className="Menu-button Menu-start" onClick={props.startGame}>
          start game
        </button>
        <button className="Menu-button Menu-tutorial" onClick={props.startMenu}>
          back
        </button>
      </div>

    </div>
  )
}

export default Tutorial