import { useState } from 'react'
import DividerHorizontal from './DividerHorizontal'
import DividerVertical from './DividerVertical'

function Game(props) {
  const { nextRound, incrementP1BadTries, incrementP2BadTries } = props
  const { challenge, p1Inventory, p2Inventory } = props
  const [selectNonce, setSelectNonce] = useState(-1)
  const [p1Component, setP1Component] = useState(null)
  const [p2Component, setP2Component] = useState(null)

  const getIsRight = (p1Component, p2Component) => (p1Component === challenge[1][0] && p2Component === challenge[1][1]) || (p1Component === challenge[1][1] && p2Component === challenge[1][0])
  const isRight = getIsRight(p1Component, p2Component)
  const isWrong = p1Component && p2Component && !isRight

  const scheduleNextRound = () => {
    setTimeout(() => {
      nextRound()
    }, 600)
  }

  const selectP1Component = (component) => {
    const p1Component = component
    setSelectNonce(Date.now())
    setP1Component(p1Component)
    if (!(component === challenge[1][0] || component === challenge[1][1])) {
      incrementP1BadTries()
    }
    if (getIsRight(p1Component, p2Component)) {
      scheduleNextRound()
    }
  }
  const selectP2Component = (component) => {
    const p2Component = component
    setSelectNonce(Date.now())
    setP2Component(p2Component)
    if (!(component === challenge[1][0] || component === challenge[1][1])) {
      incrementP2BadTries()
    }
    if (getIsRight(p1Component, p2Component)) {
      scheduleNextRound()
    }
  }

  return (
    <>
    <div className="Game">
      <div className="Player-1-space">
        {p1Inventory.map((component, i) =>
          <div className="Emoji" key={i} onClick={() => selectP1Component(component)}>{component}</div>
        )}
      </div>
      <DividerHorizontal num={1}/>
      <DividerVertical num={1}/>
      <div className="Score-portrait">
        Score: 32
      </div>
      <div className="Emoji-formula">
        <div className="Score-landscape">
          <div>Score</div>
          <div>32</div>
        </div>
        <div className="Formula-component">{p1Component ?? '?'}</div>
        <div className="Formula-sign">+</div>
        <div className="Formula-component">{p2Component ?? '?'}</div>
        <div className="Formula-sign">=</div>
        <div key={selectNonce} className={`Formula-result ${isWrong ? 'Formula-result-wrong' : ''} ${isRight ? 'Formula-result-right' : ''}`}>
          {challenge[0]}
        </div>
      </div>
      <DividerHorizontal num={2}/>
      <DividerVertical num={2}/>
      <div className="Player-2-space">
        {p2Inventory.map((component, i) =>
          <div className="Emoji" key={i} onClick={() => selectP2Component(component)}>{component}</div>
        )}
      </div>
    </div>
    <div className="Load-font">Load font</div>
    </>
  );
}

export default Game;
